export const doRequest = async (
  endpoint: string,
  options?: {
    headers: Record<string, string>;
  }
) => {
  try {
    const res = await fetch(endpoint, options);
    if (!res.ok) {
      throw new Error(`Request failed with status ${res.status}`);
    }
    return res.json();
  } catch (error) {
    throw error;
  }
};
